@media print {
  @page {
    size: A4;
  }
  .hide-to-print {
    display: none;
  }
  .content-to-print {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .screen-to-print {
    background: white !important;
    padding-bottom: 0px !important;
  }
  .page-break-to-print {
    page-break-before: avoid !important;
  }
  thead {
    display: table-row-group !important;
  }
  .indicator-to-print {
    background-color: white !important;
  }
  .filter-to-print {
    zoom: 65%;
  }
  .triage-chart-to-print {
    zoom: 80%;
  }
  .energy-level-chart-to-print {
    zoom: 80%;
  }
  .emotions-chart-to-print {
    zoom: 70%;
  }
  .follow-up-questions-chart-to-print {
    zoom: 70%;
  }
  .row-to-print {
    display: block;
    page-break-inside: avoid !important;
  }
  .div-chart-table-to-print {
    display: block !important;
  }
  .navbar-to-print {
    display: block;
    max-height: 85px !important;
  }
  .menu-school-to-print > div {
    max-width: 100% !important;
  }

  @supports (-moz-appearance: none) {
    .logo-to-print {
      color: #000000 !important;
    }
  }
}
