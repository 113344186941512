* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* iOS users were reporting issues with text input not working on older
   versions, inlcuding iPads that no longer recieve updates. See
   https://armno.in.th/blog/css-user-select/ for details. 
*/
input, textarea {
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#uploods-wrapper > div:first-child > div:first-child {
  min-height: 130px;
}
/* 
@media (max-width: 1200px) { html { font-size: 90%; } }
@media (max-width: 700px) { html { font-size: 85%; } } */

.clip-bottom {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}